/*@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap');

@font-face {
  font-family: 'byrdsimply';
  src: url('/assets/fonts/byrdsimply-regular.woff2') format('woff2'), url('/assets/fonts/byrdsimply-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'byrdsimply';
  src: url('/assets/fonts/byrdsimply-bold.woff2') format('woff2'), url('/assets/fonts/byrdsimply-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'byrdsimply';
  src: url('/assets/fonts/byrdsimply-extrabold.woff2') format('woff2'), url('/assets/fonts/byrdsimply-extrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Basis Grotesque Pro';
  src: url('/assets/fonts/BasisGrotesque-Pro-Regular.woff2') format('woff2'), url('/assets/fonts/BasisGrotesque-Pro-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Basis Grotesque Pro';
  src: url('/assets/fonts/BasisGrotesque-Pro-Black.woff2') format('woff2'), url('/assets/fonts/BasisGrotesque-Pro-Black.woff') format('woff');
  font-weight: 900;
  /* Black */
  font-style: normal;
}

body {
  font-family: Basis Grotesque Pro, sans-serif;
}
h1,
h2,
h3,
h4 {
  font-family: byrdsimply, sans-serif;
}

* {
  font-family: Basis Grotesque Pro, sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.jt-float-right {
  float: right;
}
